import { inject, Injectable } from '@angular/core';
import { ConvertHelper } from '../../helpers/convert.helper';

export function maskMoney(): IMaskMoney {
    return inject(MoneyService).maskMoney;
}

@Injectable({
    providedIn: 'root',
})
export class MoneyService {
    maskMoney: IMaskMoney;

    constructor() {}

    format(value: string | number): string {
        return ConvertHelper.convertToMaskMoney(value, {
            prefix: `${this.maskMoney.symbol} `,
            suffix: '',
            decimalSeparator: this.maskMoney.decimalSeparator,
            thousandSeparator: this.maskMoney.thousandSeparator,
        });
    }

    formatPercentage(value: string | number): string {
        return ConvertHelper.convertToMaskMoney(value, {
            prefix: '',
            suffix: '%',
            decimalSeparator: this.maskMoney.decimalSeparator,
            thousandSeparator: this.maskMoney.thousandSeparator,
        });
    }

    formatQuantity(value: string | number, isUnitary = false, fractionDigits = 2): string {
        if (isUnitary) {
            return String(Number(value));
        }

        return ConvertHelper.convertToMaskMoney(
            value,
            {
                prefix: '',
                suffix: '',
                decimalSeparator: this.maskMoney.decimalSeparator,
                thousandSeparator: this.maskMoney.thousandSeparator,
            },
            fractionDigits,
        );
    }
}

export interface IMaskMoney {
    decimalSeparator: string;
    thousandSeparator: string;
    symbol: string;
    isoCode: string;
}
